<template>
  <div>
    <v-sheet>
      <v-img v-if="!g_darkmode" src="@/assets/homepage/home-profile-bg.png">
        <v-container>
          <v-row class="pa-2 mx-2" justify="center">
            <v-row
              no-gutters
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'margin-profile-mob'
                  : token
                  ? 'margin-profile-no-token'
                  : 'margin-profile'
              "
            >
              <v-col cols="12" sm="8" md="5" class="d-flex align-end mb-5">
                <v-img
                  v-if="
                    $vuetify.breakpoint.mdAndUp &&
                      $vuetify.breakpoint.name !== 'md'
                  "
                  :src="data.profile.profile_path"
                  max-height="350"
                  max-width="500"
                  contain
                />
                <v-img
                  v-else-if="$vuetify.breakpoint.name === 'md'"
                  :src="data.profile.profile_path"
                  max-height="250"
                  max-width="350"
                  contain
                />
                <v-img
                  v-else
                  :src="data.profile.profile_path"
                  max-height="250"
                  max-width="350"
                  contain
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="7"
                :class="$vuetify.breakpoint.mdAndUp && 'pl-15'"
                class="text-left"
              >
                <v-card class="rounded-xl elevation-0 pa-5 white">
                  <div class="display-1 font-weight-medium mb-3">
                    {{ $t("app.profile") }}
                  </div>
                  <p
                    :class="
                      $vuetify.breakpoint.smAndDown ? 'body-2' : 'subtitle'
                    "
                  >
                    <span v-html="data.profile.profile_desc" />
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-img>

      <v-container v-if="g_darkmode">
        <v-row class="pa-2 mx-2" justify="center">
          <v-row
            no-gutters
            :class="
              $vuetify.breakpoint.xsOnly
                ? 'margin-profile-mob'
                : token
                ? 'margin-profile-no-token'
                : 'margin-profile'
            "
          >
            <v-col
              cols="12"
              sm="12"
              md="5"
              class="d-flex justify-xl-center align-md-center justify-center mb-5"
            >
              <v-img
                v-if="
                  $vuetify.breakpoint.mdAndUp &&
                    $vuetify.breakpoint.name !== 'md'
                "
                :src="data.profile.profile_path"
                max-height="350"
                max-width="500"
                contain
              />
              <v-img
                v-else-if="$vuetify.breakpoint.name === 'md'"
                :src="data.profile.profile_path"
                max-height="250"
                max-width="350"
                contain
              />
              <v-img
                v-else
                :src="data.profile.profile_path"
                max-height="250"
                max-width="350"
                contain
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="7"
              :class="$vuetify.breakpoint.mdAndUp && 'pl-15'"
              class="text-left"
            >
              <v-card
                class="rounded-xl elevation-0 pa-5"
                :class="{ 'grey lighten-1': g_darkmode }"
              >
                <div class="display-1 font-weight-medium mb-3">
                  {{ $t("homepage.profile") }}
                </div>
                <p
                  :class="$vuetify.breakpoint.smAndDown ? 'body-2' : 'subtitle'"
                >
                  <span v-html="data.profile.profile_desc" />
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "Profile",
  props: {
    token: String,
    g_darkmode: Boolean,
    data: Object
  }
};
</script>

<style lang="scss" scoped>
.home-profile {
  height: 100%;
  width: 80px;
}
.margin-profile {
  padding-top: 100px;
}
.margin-profile-no-token {
  padding-top: 60px;
}
.margin-profile-mob {
  padding-top: 60px;
}
</style>
