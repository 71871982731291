<template>
  <v-sheet
    v-if="data.profile.purpose_desc"
    :class="darkmode ? 'black' : 'white'"
  >
    <v-container>
      <v-row class="ma-6">
        <v-col cols="12" class="text-left">
          <v-row class="title font-weight-medium mb-3 pl-3" align="center">
            <img
              class="homepage-icon d-inline"
              src="@/assets/homepage/home-purpose.svg"
            />
            {{ $t("homepage.purpose") }}
          </v-row>
          <p :class="$vuetify.breakpoint.smAndDown ? 'body-2' : 'subtitle'">
            <span v-html="data.profile.purpose_desc" />
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: "Purpose",
  props: {
    data: Object
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  }
};
</script>
