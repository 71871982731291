<template>
  <v-sheet :class="{ 'grey lighten-4': !g_darkmode }">
    <v-container class="pa-12">
      <div class="display-1 text-center mb-6">
        {{ $t("homepage.calendar") }}
      </div>
      <v-sheet class="calender-wrapper rounded-xl noselect">
        <v-row>
          <v-col cols="12" sm="5" class="white--text gradient-primary">
            <div class="d-flex justify-center align-center pa-6">
              <div>
                <div class="display-1">{{ day }}</div>
                <div class="display-4 font-weight-bold">{{ date }}</div>
                <div class="headline">{{ monthYear }}</div>
              </div>
            </div>
            <v-timeline v-if="dateEvents.length" dense>
              <v-timeline-item
                v-for="(event, i) in dateEvents"
                :key="i"
                :color="event.background_color"
                class="text-left"
                small
                fill-dot
              >
                <span>
                  {{ event.name }}
                </span>
              </v-timeline-item>
            </v-timeline>
          </v-col>
          <v-col
            v-if="showCalendar"
            cols="12"
            sm="7"
            :class="{ white: !g_darkmode, 'grey lighten-1': g_darkmode }"
          >
            <div
              class="text-right mt-4 mr-6 headline"
              :style="g_darkmode && 'color: #1e1e1e'"
            >
              <v-icon color="primary" class="mr-1">mdi-calendar</v-icon>
              {{ year }}
            </div>
            <div class="ma-6">
              <v-date-picker
                v-model="today"
                :locale="g_language"
                :events="markEvents"
                :max="max"
                :min="min"
                class="elevation-0"
                reactive
                color="primary"
                first-day-of-week="1"
                no-title
                full-width
                @change="changeDate"
              />
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-sheet>
</template>

<script>
import moment from "moment/moment";
import { get_event_list } from "@/api/admin/academic/schoolYear";

export default {
  name: "Calendar",
  props: {
    g_darkmode: Boolean,
    g_language: String,
    g_user: Object
  },
  mounted() {
    moment.locale(this.g_language);
    this.getData();
    this.min = this.g_user.active_schoolyear.start_date;
    this.max = this.g_user.active_schoolyear.end_date;
  },
  data: () => ({
    today: moment().format("YYYY-MM-DD"),
    day: moment().format("dddd"),
    date: moment().format("DD"),
    monthYear: moment().format("MMMM, YYYY"),
    year: moment().format("YYYY"),
    eventData: [],
    showCalendar: false,
    dateEvents: [],
    min: "",
    max: ""
  }),
  methods: {
    getEvents(date) {
      return this.eventData.filter(
        item =>
          moment(date).isBetween(item.start_date, item.end_date) ||
          moment(date).isSame(item.start_date) ||
          moment(date).isSame(item.end_date)
      );
    },
    changeDate(date) {
      this.dateEvents = this.getEvents(date);
      this.day = moment(date).format("dddd");
      this.date = moment(date).format("DD");
      this.monthYear = moment(date).format("MMMM, YYYY");
    },
    markEvents(date) {
      const events = this.getEvents(date);
      return events.map(event => event.background_color);
    },
    async getData() {
      try {
        const { data } = await get_event_list();
        if (data.code) {
          this.eventData = data.data;
        }
      } catch (err) {
        console.error("Error at method getData()\n", err);
      } finally {
        this.showCalendar = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.calender-wrapper {
  overflow: hidden;
  .v-date-picker-header__value {
    display: none;
  }
  .today-event {
    max-height: 70px;
    overflow: auto;
  }
  #style-3::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  #style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  #style-3::-webkit-scrollbar-thumb {
    background-color: #27ace2;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.theme--light.v-timeline:before {
  background-color: whitesmoke !important;
  width: 3px;
  border-radius: 10px;
}
</style>
