<template>
  <div class="slider-wrapper">
    <v-carousel show-arrows-on-hover hide-delimiters>
      <v-carousel-item v-for="(slide, i) in data" :key="i">
        <v-img
          :src="slide.path"
          :lazy-src="slide.path"
          :aspect-ratio="16 / 9"
          min-height="100%"
          gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
        >
          <v-row class="pt-16">
            <v-card-text class="text-center white--text">
              <p class="display-1 font-weight-bold">{{ slide.title }}</p>
              <span class="title"> {{ slide.description }}</span>
            </v-card-text>
          </v-row>
        </v-img>
      </v-carousel-item>
    </v-carousel>

    <!-- float card -->
    <v-container class="pa-0" v-if="!token">
      <v-row no-gutters justify="center" align="center">
        <v-card
          :class="
            $vuetify.breakpoint.smAndUp
              ? 'registration-wrapper mt-5 rounded-lg'
              : 'registration-wrapper-mob rounded-lg'
          "
          :max-width="$vuetify.breakpoint.xsOnly ? 600 : 850"
          elevation="10"
        >
          <v-row no-gutters>
            <v-col
              v-if="!$vuetify.breakpoint.smAndDown"
              sm="12"
              md="4"
              align-self="center"
            >
              <strong class="subtitle-1 font-weight-bold">
                {{ $t("homepage.subtitle") }}
              </strong></v-col
            >
            <v-col sm="6" md="4">
              <v-row
                class="registration-btn mx-auto"
                :class="$vuetify.breakpoint.xsOnly && 'ma-4'"
                @click="directTo('student')"
              >
                <v-col
                  cols="12"
                  sm="5"
                  v-if="!$vuetify.breakpoint.xsOnly"
                  class="d-flex justify-center"
                  :style="
                    $vuetify.breakpoint.mdAndUp &&
                      'border-left: 1px solid lightgrey'
                  "
                >
                  <img
                    class="registration-logo pa-3"
                    src="@/assets/homepage/siswa-baru.svg"
                    max-width="100px"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="7"
                  align-self="center"
                  :class="
                    $vuetify.breakpoint.xsOnly ? 'text-center' : 'text-left'
                  "
                  ><div class="body-1">{{ $t("homepage.registration") }}</div>
                  <div class="title">
                    {{ $t("homepage.new_student") }}
                  </div></v-col
                >
              </v-row>
            </v-col>
            <v-col sm="6" md="4">
              <v-row
                class="registration-btn mx-auto"
                :class="$vuetify.breakpoint.xsOnly && 'ma-4'"
                @click="directTo('teacher')"
              >
                <v-col
                  cols="12"
                  sm="5"
                  v-if="!$vuetify.breakpoint.xsOnly"
                  class="d-flex justify-center"
                  style="border-left: 1px solid lightgrey"
                  ><img
                    class="registration-logo pa-3"
                    src="@/assets/homepage/guru-staff.svg"
                    max-width="100px"
                /></v-col>
                <v-col
                  cols="12"
                  sm="7"
                  align-self="center"
                  :class="
                    $vuetify.breakpoint.xsOnly ? 'text-center' : 'text-left'
                  "
                  ><div class="body-1">{{ $t("homepage.registration") }}</div>
                  <div class="title">
                    {{ $t("homepage.teacher_staff") }}
                  </div></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
    <!-- float card -->
  </div>
</template>

<script>
export default {
  props: {
    token: String,
    data: Array
  },
  data() {
    return {
      expand: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.expand = true;
    }, 1000);
  },
  methods: {
    directTo(param) {
      this.$router.push("/ppdb/" + param);
    }
  }
};
</script>

<style lang="scss" scoped>
.slider-wrapper {
  position: relative;
}
.registration-wrapper {
  position: absolute;
  width: 100%;
  z-index: 1;
  .registration-logo {
    max-width: 150px;
  }
  .registration-btn {
    cursor: pointer;
  }
}

.registration-wrapper-mob {
  position: absolute;
  width: 90%;
  z-index: 1;
  .registration-btn {
    cursor: pointer;
  }
}
</style>
