<template>
  <v-sheet
    v-if="data.profile.misi_desc && data.profile.visi_desc"
    :class="g_darkmode ? 'grey darken-4' : 'grey lighten-5'"
    min-height="350"
  >
    <v-container>
      <v-row class="pa-4 mt-3">
        <v-col
          md="5"
          class="d-flex justify-xl-center align-md-center justify-center"
        >
          <v-img
            v-if="
              $vuetify.breakpoint.mdAndUp && $vuetify.breakpoint.name !== 'md'
            "
            :src="data.profile.visi_misi_path"
            max-height="350"
            max-width="550"
            contain
          />
          <v-img
            v-else-if="$vuetify.breakpoint.name === 'md'"
            :src="data.profile.visi_misi_path"
            max-height="250"
            max-width="350"
            contain
          />
          <v-img
            v-else
            :src="data.profile.visi_misi_path"
            max-height="250"
            max-width="350"
            contain
          />
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.mdAndDown && 'mt-10'"
          cols="12"
          sm="12"
          md="6"
          class="text-left"
        >
          <v-row>
            <v-col cols="12" class="text-left">
              <v-card
                class="rounded-xl elevation-0 pa-5"
                :class="{ 'grey lighten-1': g_darkmode }"
              >
                <div class="title font-weight-medium mb-3 pl-3">
                  <img
                    class="homepage-icon d-inline"
                    src="@/assets/homepage/home-vision.svg"
                  />
                  {{ $t("homepage.vision") }}
                </div>
                <p
                  :class="$vuetify.breakpoint.smAndDown ? 'body-2' : 'subtitle'"
                  class="pl-3"
                >
                  <span v-html="data.profile.visi_desc" />
                </p>
              </v-card>
            </v-col>
            <v-col cols="12" class="text-left mt-5">
              <v-card
                class="rounded-xl elevation-0 pa-5"
                :class="{ 'grey lighten-1': g_darkmode }"
              >
                <div class="title font-weight-medium mb-3 pl-3">
                  <img
                    class="homepage-icon d-inline"
                    src="@/assets/homepage/home-mission.svg"
                  />
                  {{ $t("homepage.mission") }}
                </div>
                <p
                  :class="$vuetify.breakpoint.smAndDown ? 'body-2' : 'subtitle'"
                  class="pl-3"
                >
                  <span v-html="data.profile.misi_desc" />
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: "VisionAndMission",
  props: { g_darkmode: Boolean, data: Object }
};
</script>

<style lang="scss" scoped>
.vision-mission-asset {
  border-radius: 20px;
  width: 360px;
  right: 10px;
  top: 0px;
  position: absolute;
  z-index: 1;
}
.mission-wrapper {
  position: relative;
  .mission-card-wrapper {
    position: absolute;
    left: -100px;
    width: 150%;
    padding-left: 100px;
  }
}
.absolute-div {
  background: white;
  height: 100%;
  width: 130px;
  position: absolute;
  left: -110px;
  top: 0px;
}
</style>
