var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-sheet',[(!_vm.g_darkmode)?_c('v-img',{attrs:{"src":require("@/assets/homepage/home-profile-bg.png")}},[_c('v-container',[_c('v-row',{staticClass:"pa-2 mx-2",attrs:{"justify":"center"}},[_c('v-row',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'margin-profile-mob'
                : _vm.token
                ? 'margin-profile-no-token'
                : 'margin-profile',attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-end mb-5",attrs:{"cols":"12","sm":"8","md":"5"}},[(
                  _vm.$vuetify.breakpoint.mdAndUp &&
                    _vm.$vuetify.breakpoint.name !== 'md'
                )?_c('v-img',{attrs:{"src":_vm.data.profile.profile_path,"max-height":"350","max-width":"500","contain":""}}):(_vm.$vuetify.breakpoint.name === 'md')?_c('v-img',{attrs:{"src":_vm.data.profile.profile_path,"max-height":"250","max-width":"350","contain":""}}):_c('v-img',{attrs:{"src":_vm.data.profile.profile_path,"max-height":"250","max-width":"350","contain":""}})],1),_c('v-col',{staticClass:"text-left",class:_vm.$vuetify.breakpoint.mdAndUp && 'pl-15',attrs:{"cols":"12","sm":"12","md":"7"}},[_c('v-card',{staticClass:"rounded-xl elevation-0 pa-5 white"},[_c('div',{staticClass:"display-1 font-weight-medium mb-3"},[_vm._v(" "+_vm._s(_vm.$t("app.profile"))+" ")]),_c('p',{class:_vm.$vuetify.breakpoint.smAndDown ? 'body-2' : 'subtitle'},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.data.profile.profile_desc)}})])])],1)],1)],1)],1)],1):_vm._e(),(_vm.g_darkmode)?_c('v-container',[_c('v-row',{staticClass:"pa-2 mx-2",attrs:{"justify":"center"}},[_c('v-row',{class:_vm.$vuetify.breakpoint.xsOnly
              ? 'margin-profile-mob'
              : _vm.token
              ? 'margin-profile-no-token'
              : 'margin-profile',attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-xl-center align-md-center justify-center mb-5",attrs:{"cols":"12","sm":"12","md":"5"}},[(
                _vm.$vuetify.breakpoint.mdAndUp &&
                  _vm.$vuetify.breakpoint.name !== 'md'
              )?_c('v-img',{attrs:{"src":_vm.data.profile.profile_path,"max-height":"350","max-width":"500","contain":""}}):(_vm.$vuetify.breakpoint.name === 'md')?_c('v-img',{attrs:{"src":_vm.data.profile.profile_path,"max-height":"250","max-width":"350","contain":""}}):_c('v-img',{attrs:{"src":_vm.data.profile.profile_path,"max-height":"250","max-width":"350","contain":""}})],1),_c('v-col',{staticClass:"text-left",class:_vm.$vuetify.breakpoint.mdAndUp && 'pl-15',attrs:{"cols":"12","sm":"12","md":"7"}},[_c('v-card',{staticClass:"rounded-xl elevation-0 pa-5",class:{ 'grey lighten-1': _vm.g_darkmode }},[_c('div',{staticClass:"display-1 font-weight-medium mb-3"},[_vm._v(" "+_vm._s(_vm.$t("homepage.profile"))+" ")]),_c('p',{class:_vm.$vuetify.breakpoint.smAndDown ? 'body-2' : 'subtitle'},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.data.profile.profile_desc)}})])])],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }