<template>
  <div class="text-center">
    <Slider :token="g_token" :data="g_school.slider_photo" />
    <Profile
      :token="g_token"
      :data="g_school"
      :g_darkmode="g_darkmode"
      id="profile"
    />
    <Purpose :data="g_school" id="purpose" />
    <VisionAndMission :data="g_school" id="vision" :g_darkmode="g_darkmode" />
    <Calendar
      id="calendar"
      :g_darkmode="g_darkmode"
      :g_language="g_language"
      :g_user="g_user"
    />
    <div :class="g_darkmode ? 'black' : 'white'" class="pt-12 text-center">
      <div class="display-1 mb-1">{{ $t("homepage.announcement") }}</div>
      <div class="subtitle font-weight-regular">
        {{ $t("homepage.announcement_sub") }}
      </div>
    </div>
    <Announcement id="announcement" />
    <div :class="g_darkmode ? 'black' : 'white'" class="pa-5 text-center">
      <div class="display-1 mb-1">{{ $t("homepage.news") }}</div>
      <div class="subtitle font-weight-regular">
        {{ $t("homepage.news_sub") }}
      </div>
    </div>
    <News id="news" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  Slider,
  Profile,
  Purpose,
  VisionAndMission,
  Calendar,
  News,
  Announcement
} from "./components";

export default {
  metaInfo: {
    title: "Homepage"
  },
  name: "homepage",
  components: {
    Announcement,
    Slider,
    Profile,
    Purpose,
    VisionAndMission,
    Calendar,
    News
  },
  computed: {
    ...mapGetters(["g_token", "g_darkmode", "g_language", "g_school", "g_user"])
  },
  sockets: {
    connect: function() {
      console.log("socket connected");
    },
    customEmit: function(data) {
      console.log(
        'this method was fired by the socket server. eg: io.emit("customEmit", data)',
        data
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.registration-logo {
  width: 100%;
}
</style>
